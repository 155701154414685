<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center class="login">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-4" v-if="user">
          <v-card-title>
            <div>
              <h3 class="mb-0">Welcome, {{ firstName }} {{ lastName }}</h3>
              <span>Your session will last until {{ expirationString }}</span>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="logout" color="primary">log out</v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="elevation-4" v-if="!user">
          <v-card-title>
            <div>
              <h3 class="mb-0">You are not logged in.</h3>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="login" color="primary">Log in</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { act, get } from '../../store/constants'
import path from '../../path'

export default {
  computed: {
    ...mapGetters({
      user: get.CURRENT_USER,
      expiration: get.EXPIRATION
    }),
    firstName () {
      if (this.user) {
        return this.user.firstName
      }
      return ''
    },
    lastName () {
      if (this.user) {
        return this.user.lastName
      }
      return ''
    },
    expirationString () {
      if (this.expiration) {
        return this.expiration.getHours() + ' h ' + this.expiration.getMinutes() + ' m ' + this.expiration.getSeconds() + ' s'
      }
      return ''
    }
  },
  methods: {
    logout () {
      this.$store.dispatch(act.LOGOUT)
      this.$router.push(path.LOGIN)
    },
    login () {
      this.$router.push(path.LOGIN)
    }
  }
}
</script>

<style>

</style>
